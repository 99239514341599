import CheckDeposit from './index.vue';
const routes = [
  {
    path: '/check-deposit',
    name: 'CheckDeposit',
    component: CheckDeposit,
    children: [
      {
        path: 'actions',
        name: 'CheckDeposit-actions',
        component: () => import('./views/CheckDepositAction.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
    ]
  }
];

export default routes;

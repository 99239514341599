import Pay from './index.vue';
const routes = [
  {
    path: '/pay',
    component: Pay,
    children: [
    ]
  }
];

export default routes;

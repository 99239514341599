/**
 * @typedef {Object} cardState
 * @property {card} selectedCard
 */

/**
 * @typedef {Object} card
 * @property {string} id
 * @property {string} accountId
 * @property {string} businessId
 * @property {string} programId
 * @property {Object} cardHolder
 * @property {string} cardHolder.id
 * @property {string} cardHolder.personId
 * @property {address} cardHolder.billingAddress
 * @property {string} cardType
 * @property {string} label
 * @property {string} limitAmount
 * @property {string} limitInterval
 * @property {Object} shipping
 * @property {address} shipping.shippingAddress
 * @property {string} shipping.eta
 * @property {string} shipping.deliveryStatus
 * @property {string} currency
 * @property {string} expiryMonth
 * @property {string} expiryYear
 * @property {string} last4
 * @property {string} cardStatus
 */
export default {
  /**
   * @type {card}
   */
  selectedCard: null,
};

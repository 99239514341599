import Vue from 'vue';
/**
 * @typedef {import('./state').cryptoState} cryptoState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get crypto transactions
     * @param {cryptoState} state
     * @returns {cryptoState['transactions']}
     */
    getCryptoTransactions(state) {
      return state.transactions;
    },
  },
  mutations: {
    /**
     * set crypto wallet list
     * @param {cryptoState} state
     * @param {cryptoState['transactions']} payload
     */
    setCryptoTransactions(state, payload) {
      state.transactions = payload;
    },
  },
  actions: {
    /**
     * fetch all crypto wallets
     * @param {Object} param0
     * @param {Function} param0.commit
     */
    getWalletById({ commit }, id) {
      return new Promise((resolve, reject) => {
        window.solid.module('walletList')
          .getCryptoAccountDetails(id)
          .then((data) => {
            commit('account/setSelectedAccount', data, {root: true});
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
    * create account
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {any} payload
     * @returns {Promise<cryptoState['wallet']>}
     */
    createCryptoWallet({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/crypto', payload)
          .then(({ data }) => {
            commit('setSelectedCryptoWallet', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch crypto transactions
     */
    fetchCryptoTransactions({commit, state}, {accountId, limit = 25, offset = 0, minAmount, maxAmount, startDate, endDate, query, txnType}) {
      return new Promise((resolve, reject) => {
        let url = `v1/crypto/${accountId}/transaction?limit=${limit}&offset=${offset}`;
        if (startDate) {
          url += `&startTime=${startDate}`;
        }
        if (endDate) {
          url += `&endTime=${endDate}`;
        }
        if (minAmount && minAmount !== '0.00' && minAmount != '0') {
          url += `&minAmount=${minAmount}`;
        }
        if (maxAmount && maxAmount !== '0.00' && maxAmount != '0') {
          url += `&maxAmount=${maxAmount}`;
        }
        if (query) {
          url += `&query=${query}`;
        }
        if (txnType) {
          url += `&txnType=${txnType}`;
        }
        http
          .get(url)
          .then(({ data }) => {
            let d = {};
            if(offset === 0) {
              d = data;
            } else {
              const transactions = {...state.transactions};
              d = {
                data: [...transactions.data,...data.data],
                total: data.total
              }
            }
            commit('setCryptoTransactions', d);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * fetch transaction by id
     */
    getCryptoTransactionById({commit}, {accountId, transactionId, format}) {
      return new Promise((resolve, reject) => {
        let url = `v1/crypto/${accountId}/transaction/${transactionId}`;
        if (format) {
          url += `?export=${format}`;
        }
        http(url, {
          method: 'GET',
          responseType: format === 'pdf' ? 'blob' : 'json'
        })
          .then(({ data }) => {
            if (format !== 'pdf') {
              commit('transaction/setSelectedTransaction', data, {root: true});
            }
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
  }
};

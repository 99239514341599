import Kyb from './index.vue';

const routes = [
  {
    path: '/kyb',
    name: 'Kyb',
    component: Kyb,
    children: [
      {
        path: 'start',
        name: 'Start',
        component: () =>
          import(
            /* webpackChunkName: "startKyb" */ './views/Start.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'eSignCallback',
        name: 'ESignCallback',
        component: () =>
          import(
            /* webpackChunkName: "eSignCallback" */ './views/EsignCallback.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: false,
          preventBrowserBack: false
        }
      },
    ]
  }
];

export default routes;

/**
 * @typedef {import('./state').contactState} contactState
 */
import state from './state';
export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get selected contact data
     * @param {contactState} state
     * @return {contactState['selectedContact']}
     */
    getSelectedContactData(state) {
      return state.selectedContact;
    },
  },
  mutations: {
    /**
     * update selected contact data
     * @param {contactState} state
     * @param {contactState['createContactData']} payload
     */
    setSelectedContact(state, payload) {
      state.selectedContact = payload;
    },
  },
  actions: {
  }
};

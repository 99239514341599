<template>
  <div class="mini-account-selector">
    <div class="right">
      <el-tag type="warning" v-if="env.VUE_APP_ENV !== 'prod'">
        TEST Mode
      </el-tag>
      <div class="dark-mode">
        <el-switch
          v-model="darkMode"
          @change="updateDarkMode" />
        Dark mode
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { getEnv } from '@/utils/env';
export default {
  data() {
    return {
      darkMode: false
    }
  },
  computed: {
    ...mapGetters('account', ['getGroupedAccounts']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters(['getDarkMode']),
    env() {
      return getEnv();
    }
  },
  mounted() {
    const container = this.$el.querySelector('.scroll');
    const el = this.$el.querySelector('.selected.account');
    if(el){
      const rect = el.getBoundingClientRect()
      container.scrollTo(rect.left, rect.top)
    }
    this.darkMode = this.getDarkMode;
  },
  methods: {
    ...mapMutations('account', ['setSelectedAccount', 'setAddAccountBackPage']),
    ...mapMutations(['setDarkMode']),
    selectAccount(a) {
      if (!this.getSelectedAccount || a.id !== this.getSelectedAccount.id) {
        this.setSelectedAccount(a);
        this.$emit('accountChanged', a);
      }
    },
    addAccount() {
      this.setAddAccountBackPage(this.$router.currentRoute.fullPath);
      this.$router.push('/account/create');
    },
    updateDarkMode(val) {
      this.setDarkMode(val);
      window.solid.darkMode(val);
    }
  },
  watch: {
    getDarkMode(val) {
      this.darkMode = val;
    }
  }
};
</script>
<style lang="scss">
.mini-account-selector {
  background: white;
  border-bottom: 1px solid #ECECEC;
  padding-left: 289px;
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 999;

  .right {
    background-color: white;
    border-left: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
    height: 68px;
    margin-left: auto;


    .dark-mode {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 10px;
      line-height: 16px;
      color: #696969;
      height: 100%;
      justify-content: center;
      .el-switch.is-checked {
        .el-switch__core {
          border-color: var(--branding-primary)!important;
          background-color: var(--branding-primary)!important;
        }
      }
    }

    .el-tag.el-tag--warning {
      color: white;
      background-color: var(--orange2);
      padding: 8px 10px;
      font-size: 14px;

      + .dark-mode {
        border-left: 1px solid #eee;
        padding-left: 30px;
        margin-left: 30px;
      }
    }
  }

  .is-dark & {
    background: #2C2C2E;
    border-color: #444447;  
    .right {
      background-color: #2C2C2E;
      border-color: #444447;
      .el-tag.el-tag--warning {
        + .dark-mode {
          border-color: #444447;
        }
      }
    }
  }
}
</style>

import store from '@/store';
import businessStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('business', businessStore);
  } else {
    if (!businessStore.registered) {
      store.registerModule('business', businessStore, {
        preserveState: !!store.state['business']
      });
      businessStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  registerStore();
}

import store from '@/store';
import kycStore from '../store/index';

export function registerStore() {
  store.registerModule('kyc', kycStore);
}

export default function init() {
  //do initial work
}

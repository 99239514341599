/**
 * @typedef {Object} cryptoState
 * @property {cryptoWalletList} cryptoWalletList
 * @property {transactions} transactions
 * @property {quote | null} selectedQuote
 * @property {buyCryptoFormData | null} buyCryptoFormData
 * @property {buyCryptoFormData | null} sellCryptoFormData
 */
export default {
  cryptoWalletList: [],
  /**
    * @typedef {object} transaction
    * @property {string} id
    * @property {string} txnType
    * @property {string} title
    * @property {string} amount
    * @property {string} transferType
    * @property {string} subType
    * @property {string} description
    * @property {string} walletId
    * @property {string} businessId
    * @property {string} programId
    * @property {string} personId
    * @property {string} balance
    * @property {string} status
    * @property {object} card
    * @property {string} card.id
    * @property {string} card.transferId
    * @property {string} card.label
    * @property {object} card.merchant
    * @property {string} card.merchant.merchantName
    * @property {string} card.merchant.merchantCity
    * @property {string} card.merchant.merchantState
    * @property {string} card.merchant.merchantCountry
    * @property {string} card.merchant.postalCode
    * @property {string} card.merchant.merchantCategory
    * @property {string} card.merchant.merchantCategoryCode
    * @property {string} card.quotedPrice
    * @property {string} card.quotedCurrency
    * @property {null} send
    * @property {null} buy
    * @property {null} sell
    * @property {null} receive
    * @property {string} createdAt
    * @property {string} txnDate
    * @property {string} currency
    * @property {string} familyId
    * @property {string} parentTxnId
    * @property {string} reviewCode
    * @property {string} reviewMessage
    * @property {string} parentTransferId
  */

  /**
   * @typedef {Object} transactions
   * @property {number} total
   * @property {transaction[]} data
   */

  /**
   * @type {transactions}
   */
  transactions: {
    total: 0,
    data: []
  },
};

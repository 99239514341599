import Kyc from './index.vue';

const routes = [
  {
    path: '/kyc',
    name: 'Kyc',
    component: Kyc,
    children: [
      {
        path: 'accountType',
        name: 'accountType',
        component: () =>
          import(
            /* webpackChunkName: "kyc-account-type" */ './views/AccountTypeSelection.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'start',
        name: 'start',
        component: () =>
          import(
            /* webpackChunkName: "start" */ './views/Start.vue'
          ),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true,
          layout: 'ao'
        }
      },
    ]
  }
];

export default routes;

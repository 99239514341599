/**
 * @typedef {import('./state').fundState} fundState
 */
import state from './state';
export default {
  namespaced: true,
  state,
  getters: {
    
  },
  mutations: {
    
  },
  actions: {
  }
};

<template>
  <div id="main-container">
    <div class="container">
      <div id="content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { networkOffline, networkOnline } from '@/utils/general';

const online = () => {
  networkOnline('layout')
}

const offline = () => {
  networkOffline('layout')
}

export default {
  created() {
    window.addEventListener('online', online);
    window.addEventListener('offline', offline);
  },
  beforeDestroy() {
    window.removeEventListener('online', online);
    window.removeEventListener('offline', offline);
  }
}
</script>
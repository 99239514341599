import store from '@/store';
import accountStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('account', accountStore);
  } else {
    if (!accountStore.registered) {
      store.registerModule('account', accountStore, {
        preserveState: !!store.state['account']
      });
      accountStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  registerStore();
}

import Card from './index.vue';
const routes = [
  {
    path: '/card',
    name: 'Card',
    component: Card,
    children: [
      {
        path: 'list',
        name: 'CardList',
        component: () => import('./views/CardList.vue')
      },
      {
        path: 'sentCardlist',
        name: 'SentCardList',
        component: () => import('./views/SentCardList.vue')
      }
    ]
  }
];

export default routes;

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import i18n from './setup/i18n';

//plugins
import axios from './plugins/axios';
// import auth0 from './plugins/auth0';
import './plugins/element';

import './plugins/gmap';

import './mixins';
import './filters';

// CSS
import '@/assets/scss/index.scss';
import { domainMemo, getBrandConfigFileMemo, getBrandKey } from './utils/env';

Vue.config.productionTip = false;

/**
 * web app initilize
 * @param {string=} from - webapp or sdk
 */
function wiseAppinit(from = 'webapp') {
  //plugins
  Vue.use(axios);

  /**
   * brand config cache at first time
   */
  domainMemo().then(() => {
    getBrandConfigFileMemo(getBrandKey()).then(() => {
      const router = require('./router').default;

      
      // Vue.use(auth0)
  
      if (from === 'webapp') {
        new Vue({
          router: router({ mode: 'history' }),
          store,
          i18n,
          render: (h) => h(App)
        }).$mount('#app');
      } else {
      //from sdk
  
        const r = router({ mode: 'abstract' });
        new Vue({
          router: r,
          store,
          i18n,
          render: (h) => h(App)
        }).$mount('#app');
  
        r.replace('/');
      }
    })
  });
}

window.wiseAppinit = wiseAppinit;

<template>
  <div>
    <div class="sidebar-backdrop"
         @click="hideSidebar"
         v-if="getIsSidebarVisible" />
    <el-aside
      datat-testid="sidebar"
      class="sidebar"
      style="width: 289px;">
      <div class="logo-sec">
        <img class="logo"
             data-testid="sidebar-logo"
             @click="goToDashboard"
             :src="logoUrl"
             alt="logo">
      </div>

      <div class="account-sec" data-testid="account-sec">
        <p class="account_name" @click="viewAll">
          {{ $t('select_account') }} <span class="icon-Arrow-small-right" />
        </p>
      </div>
          
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo" router>
        <el-menu-item index="/dashboard/home" @click="hideSidebar">
          <i class="iconMenu-overview" />
          <span>{{ $t('side_menu_overview') }}</span>
        </el-menu-item>
        <el-menu-item
          index="/transaction/list"
          @click="hideSidebar"
          v-if="getConfigData.isTransactionsEnabled">
          <i class="icon-transactions-new" />
          <span>{{ $t('side_menu_transactions') }}</span>
        </el-menu-item>
        <template v-if="getSelectedAccount.type === 'cardAccount'">
          <el-menu-item
            index="/card/list"
            @click="hideSidebar"
            v-if="getConfigData.isCardsEnabled">
            <i class="iconMenu-card" />
            <span>{{ $t('side_menu_cards') }}</span>
          </el-menu-item>
        </template>
        <template v-else>
          <el-submenu v-if="getConfigData.isCardsEnabled && !isCryptoAccount" index="cards-submenu">
            <template slot="title">
              <i class="iconMenu-card" /> {{ $t('side_menu_cards') }}
            </template>
            <el-menu-item
              index="/card/list"
              @click="hideSidebar">
              <span>{{ $t('my_cards') }}</span>
            </el-menu-item>
            <el-menu-item
              index="/card/sentCardlist"
              @click="hideSidebar">
              <span>{{ $t('sent_cards') }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            index="/fund"
            @click="hideSidebar"
            v-if="getConfigData.isTransferMoneyEnabled && !isCryptoAccount">
            <i class="iconMenu-transfer" />
            <span>{{ $t('transfer_money') }}</span>
          </el-menu-item>
          <el-menu-item
            index="/contact/list"
            @click="hideSidebar"
            v-if="getConfigData.isSendMoneyEnabled && !isCryptoAccount">
            <i class="iconMenu-send" />
            <span>{{ $t('send_money') }}</span>
          </el-menu-item>
        </template>
      </el-menu>
      <div class="menu-footer"
           v-if="!getIsMobileLayout"
           @click="goToProfile">
        <div class="initialsInfoLight">
          <span>{{ shortName }}</span>
        </div>
        <div class="user-name">
          {{ getPersonDetails.firstName + ' ' +getPersonDetails.lastName }}
        </div>
        <span class="icon-arrow-right" />
      </div>
    </el-aside>
  
    <Drawer class="default-drawer" :components="userProfileRoutes" :show.sync="showUserProfile" v-if="showUserProfile" />
    <Drawer class="default-drawer" :components="businessDetailsRoutes" :show.sync="showBusinessDetails" v-if="showBusinessDetails" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Drawer from '@/components/Drawer.vue';
export default {
  components: {
    Drawer
  },
  data() {
    return {
      showUserProfile: false,
      showBusinessDetails: false,
      userProfileRoutes: [
        {
          name: 'UserProfile',
          default: true,
          component: () => import('@m/dashboard/views/UserProfileDropin.vue')
        }
      ],
      businessDetailsRoutes: [
        {
          name: 'BusinessDetails',
          default: true,
          component: () => import('@m/business/views/BusinessDetailsDropin.vue')
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['getIsSidebarVisible', 'getIsMobileLayout']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('account', ['isBusinessAccountType', 'getSelectedAccount']),
    ...mapGetters('dashboard', ['getConfigData']),
    shortName() {
      let name = '';
      if(this.getPersonDetails.firstName) {
        name = this.getPersonDetails.firstName[0]; 
      }
      if(this.getPersonDetails.lastName) {
        name+=this.getPersonDetails.lastName[0]; 
      }
      return name;
    },
    getSelectedBusinessName() {
      if(this.getSelectedBusiness != null) {
        return this.getSelectedBusiness.legalName;
      }
      return ''
    },
    setDrawerSize() {
      return this.getIsMobileLayout ? '100%': '30%';
    },
    isCryptoAccount() {
      return !!this.getSelectedAccount.cryptoAddress
    },
  },
  methods: {
    ...mapMutations(['setIsSidebarVisible']),
    viewAll() {
      this.$router.push('/account/all');
      this.hideSidebar();
    }, 
    goToProfile() {
      this.showUserProfile = true;
    },
    goToBusiness() {
      this.showBusinessDetails = true;
    },
    hideSidebar() {
      this.setIsSidebarVisible(false);
    }
  }
}
</script>
<style lang="scss" scoped>
    .sidebar-backdrop {
      position: fixed;
      inset: 0;
      z-index: 999;
    }
    .sidebar {
        width: 289px;
        min-height: 670px;
        top: 0;
        left: 0;
        min-height: 100vh;
        background-color: #FFFFFF;
        border-right: 1px solid #E8EBEE;
        overflow-x: hidden;
        position: fixed;
         z-index: 1000;
        .logo-sec {
            // min-height: 120px;
            border-bottom: 1px solid #E8EBEE;
            padding: 15px 24px 15px 24px;
            img {
                max-width: 245px;
                width: auto;
                height: 34px;
            }
        }
        .el-menu {
            border-right: none;
        }
        .menu-footer {
            display: flex;
            align-items: center;
            height: 79px;
            width: 289px;
            border-top: 1px solid #E8EBEE;
            padding: 12px;
            position: absolute;
            bottom: 0;
            @media (max-height: 670px) {
              position: relative;
            }
            .initialsInfoLight {
                color: var(--branding-primary);
            }
            .user-name {  
                font-size: 14px;
                line-height: 18px;
                padding: 15px 16px;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 16px;
            }
            .icon-arrow-right {
                font-size: 14px;
                color: var(--grey-1);
                position: absolute;
                right: 16px;
            }
        }
        .el-menu-item { 
            height: 51px;
            line-height: 51px;
            margin-bottom: 15px;
            font-size: 18px;
            &:hover, &:focus {
              background-color: var(--light-grey-3);
            }
            i {
                color: var(--branding);
            }
            span {
              color: var(--branding);
              margin-left: 15px;
            }
            &.is-active {
                background-color: var(--branding-primary);
                color: #FFF;
                i, span {
                    color: #FFF;
                }

            }
            
        }
        @media (max-width: 992px) {
          transform: translate3d(-100%, 0, 0);
          transition: transform 0.3s;

          .is-sidebarvisible & {
            transform: translate3d(0, 0, 0);
          }
        }
    }
    
    .account-sec {
        border-bottom: 1px solid #E8EBEE;
        padding: 19px 32px 14px 24px;
        margin-bottom: 8px;
        
        .account_name {
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          
          span {
            padding-left: 24px;
            font-size: 14px;
            margin-left: auto;
            color: #727272;
          }
        }
    }
    .go-to {
        color: var(--grey-1);
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 15px;
        span {
            padding-left: 10px;
            font-size: 12px;
        }
    }
</style>
<style lang="scss">
.sidebar {
  .el-submenu__title { 
    height: 51px;
    line-height: 51px;
    margin-bottom: 0;
    font-size: 18px;
    color: var(--branding);
    &:hover, &:focus {
      background-color: var(--light-grey-3);
    }
    i {
      color: var(--branding);
    }
    .iconMenu-card {
      margin-right: 15px;
    }
    &.is-active {
      background-color: var(--branding-primary);
      color: #FFF;
      i {
        color: #FFF;
      }
    }
  }
  .el-submenu {
    .el-menu {
      .el-menu-item {
        height: 51px;
        line-height: 51px;
        font-size: 16px;
        margin: 0;
      }
    }
  }
  .is-dark & {
    background: #2C2C2E;
    border-color: #444447;
    .logo-sec {
      border-color: #444447;
      .dark-mode {
        border-color: #444447;
      }
    }
    .account-sec {
      border-color: #444447;
      .account_name {
        color: #fff;
      }
    }
    .el-menu {
      background: transparent;
      .el-submenu__title {
        i {
          color: #727272;
        }
      }
      .el-menu-item,
      .el-submenu__title {
        color: #fff;
        span,
        i {
          color: #fff;
        }
        &:hover,
        &.is-active {
          color: #2C2C2E;
          background-color: #fff;
          span,
          i {
            color: #2C2C2E;
          }
        }
      }
    }
    .menu-footer {
      border-color: #444447;
      .user-name {
        color: #fff;
      }
      .initialsInfoLight {
        background: #000;
        span {
          color: #fff;
        }
      }
    }
  }
}
</style>
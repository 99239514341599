import store from '@/store';
import cryptoStore from '../store/index';

export function registerStore() {
  store.registerModule('crypto', cryptoStore);
}

export default function init() {
  registerStore();
}

import Account from './index.vue';

const routes = [
  {
    path: '/account',
    name: 'Account',
    component: Account,
    children: [
      {
        path: 'list',
        name: 'AccountListDashboard',
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        },
        props: { activeTab: 'Cash' },
        component: () =>
          import(
          /* webpackChunkName: "ListAccounts" */ './views/ListAccounts.vue'
          )
      },
      {
        path: 'all',
        name: 'AccountListLarge',
        redirect: {name: 'AccountListDashboard'},
      },
      {
        path: 'create',
        name: 'AddAccount',
        meta: {
          layout: 'ao'
        },
        component: () =>
          import(/* webpackChunkName: "SetupWalletOptions" */ './views/SetupWalletOptions.vue')
      },
      {
        path: 'details',
        name: 'AccountDetails',
        component: () =>
          import(
            /* webpackChunkName: "AccountDetails" */ './views/AccountDetailsDropin.vue'
          )
      },
      {
        path: 'setup',
        name: 'SetupAccount',
        meta: {
          layout: 'ao'
        },
        component: () =>
          import(
            /* webpackChunkName: "SetupAccount" */ './views/SetupWallet.vue'
          )
      },
    ]
  }
];

export default routes;

/**
 * @typedef {import('./state').kycState} kycState
 */
import state from './state';

export default {
  namespaced: true,
  state,
  mutations: {
    /**
     * set kyc data
     * @param {kycState} state
     * @param {kycState['kycData']} payload
     */
    setKycData(state, payload) {
      state.kycData = payload;
    },
  },
  actions: {
    /**
     * fetch kyc
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} personId
     */
    fetchKyc({ commit }, personId) {
      return new Promise((resolve, reject) => {
        window.solid.module('kyc')
          .getKycDetails(personId)
          .then((/** @type {import('./state').kycState['kycData']} */ data) => {
            commit('setKycData', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  },
  getters: {
    /**
     * get onfido token from state
     * @param {kycState} state
     * @returns {kycState['kycData']|null}
     */
    getKycData(state) {
      if (state && state.kycData && state.kycData.id) {
        return state.kycData;
      }
      return null;
    }
  }
};

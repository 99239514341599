import Business from './index.vue';
const routes = [
  {
    path: '/business',
    name: 'Business',
    component: Business,
    children: [
      {
        path: 'details',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        name: 'BusinessDetailsScreen',
        component: () => import('./views/BusinessDetailsDropin.vue')
      }
    ]
  }
];

export default routes;

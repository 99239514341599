/**
 * @typedef {Object} transactionState
 * @property {Object} transactions
 * @property {Array.<transactions>} transactions.data
 * @property {number} transactions.total
 * @property {transactions} recentTransactions
 * @property {transactions} selectedTransaction
 * @property {filters} filters
 */
export default {
  /**
   * @typedef {Object} transactions
   * @property {string} id
   * @property {string} type
   * @property {string} amount
   * @property {string} description
   * @property {string} txnType
   * @property {string} transferType
   * @property {string} accountId
   * @property {intrabank} intrabank
   * @property {string} txnDate
   *
   * @typedef {Object} intrabank
   * @property {string} transferId
   * @property {string} contactId
   * @property {string} contactName
   * @property {string} accountNumber
   * @property {string} routingNumber
   *
   */
  transactions: {
    data: [],
    total: 0
  },
  recentTransactions: [],
  /**
   * @type {transactions}
   */
  selectedTransaction: null,
  /**
   * @typedef {Object} filters
   * @property {string} contactId
   * @property {string} cardId
   */
  filters: {
    contactId: null,
    cardId: null,
    dateRangeValue: null,
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    txnType: null,
    transferType: null,
    subType: null,
    cardIdFromFilter: null
  },

  dropinFilters: {},
};

import Crypto from './index.vue';

const routes = [
  {
    path: '/crypto',
    name: 'Crypto',
    component: Crypto,
    children: [
    ]
  }
];

export default routes;

import store from '@/store';
import transactionStore from '../store/index';

export function registerStore() {
  store.registerModule('transaction', transactionStore);
}

export default function init() {
  //do initial work
  registerStore();
}

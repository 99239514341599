/**
 * @typedef {Object} dashbaordState
 */
export default {
  config: {
    isTransactionsEnabled:  false,
    isCardsEnabled: false,
    isTransferMoneyEnabled: false,
    isSendMoneyEnabled: false,
  }
};

import { getBrandConfig, getBrandEnv } from '@/utils/env';
import Vue from 'vue';

/**
 * @typedef {import('./state').userState} userState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get person details state
     * @param {userState} state
     */
    getPersonDetails(state) {
      return state.personDetails;
    },
    /**
     * get user details state
     * @param {userState} state
     */
    getUserDetails(state) {
      return state.userDetails;
    },
    /**
     * get user auth token state
     * @param {userState} state
     */
    getUserAuthToken(state) {
      return state.authTokenDetails;
    },
    /**
     * check user logged in or not
     * @param {userState} state
     */
    isUserLoggedIn(state) {
      return state.authTokenDetails && state.authTokenDetails.accessToken
        ? true
        : false;
    },
    /**
     * get program details
     * @param {userState} state
     * @return {userState['programDetails']} state
     */
    getProgramDetails(state) {
      return state.programDetails;
    },
    /**
     * get program details
     * @param {userState} state
     * @return {userState['programDetails']} state
     */
    getBrandColor(state) {
      return state.programDetails && state.programDetails.brand && state.programDetails.brand.primaryColor && state.programDetails.brand.primaryColor.length !== 0 ? state.programDetails.brand.primaryColor : getBrandConfig('primaryColor','#141414');
    },
    /**
     * get user login with captcha
     * @param {userState} state
     */
    getLoginWithCaptcha(state) {
      return state.loginWithCaptcha;
    },
  },
  mutations: {
    /**
     * update user details state
     * @param {userState} state
     * @param {userDetails} payload
     */
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    /**
     * update user auth token state
     * @param {userState} state
     * @param {authTokenDetails} payload
     */
    setUserAuthToken(state, payload) {
      state.authTokenDetails = payload;
    },
    /**
     * update person details state
     * @param {userState} state
     * @param {personDetails} payload
     */
    setPersonDetails(state, payload) {
      state.personDetails = payload;
    },
    /**
     * update user otp state
     * @param {userState} state
     * @param {string} payload
     */
    updateUserOtp(state, payload) {
      state.userDetails.otp = payload;
    },
    /**
     * update program details
     * @param {userState} state
     * @param {userState['programDetails']} payload
     */
    setProgramDetails(state, payload) {
      state.programDetails = payload;
    },
    /**
     * set user login with captcha
     * @param {userState} state
     */
    setLoginWithCaptcha(state, payload) {
      state.loginWithCaptcha = payload;
    },
  },
  actions: {
    /**
     * user auth login
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} mobileNumber
     * @param {string} captchaToken
     * @returns {Promise<userDetails>}
     */
    startLogin() {
      return new Promise((resolve, reject) => {
        /** @type {import('@auth0/auth0-spa-js').Auth0Client} */
        const auth0 = Vue.$auth0;
        auth0.loginWithRedirect({
          authorizationParams: {
            redirect_uri: window.location.origin + '/login-callback',
            responseType: 'token id_token',
            scope: 'openid profile phone offline_access',
            audience: getBrandEnv('auth0Audience'),
            logoUrl: window.location.origin + '/solid/logos/Logo.svg',
            supportedCountries: getBrandConfig('supportedCountries', ['US']) ,
            auth0Terms: getBrandConfig('auth0Terms', 'https://help.solidfi.com/hc/en-us/articles/4405763549979-Solid-Program-Terms-of-Service-Consumer-'),
            auth0Privacy: getBrandConfig('auth0Privacy', 'https://help.solidfi.com/hc/en-us/articles/4405726967195-Solid-Privacy-Policy'),
            primaryColor: getBrandConfig('primaryColor', '#141414') ,
            defaultCountry: 'US'
          }
        }).then(() => {
          //logged in. you can get the user profile like this:
          Vue.$auth0.getUser().then(user => {
            resolve(user);
          });
        }).catch(reject);

      })
    },
    /**
     * Verify token
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    verifyToken({commit}, {idNumberLast4, accessToken}) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/auth/register', {
            clientId: getBrandEnv('auth0ClientId'),
            idNumberLast4,
          }, {
            headers: {
              'AUTHORIZATION': 'Bearer ' + accessToken
            }
          })
          .then(({ data }) => {
            commit('setUserAuthToken', data);
            http.defaults.headers.common.AUTHORIZATION =
            'Bearer ' + data.accessToken;
            // commit('setUserDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          })
      })
    },
    /**
     * handle login callback
     */
    handleRedirectCallback() {
      return Vue.$auth0.handleRedirectCallback();
    },
    /**
     * check session
     */
    checkSession({ commit }) {
      return new Promise((resolve, reject) => {

        const differentAudienceOptions = {
          cacheMode: 'off',
          authorizationParams: {
            redirect_uri: window.location.origin + '/login-callback',
            responseType: 'token id_token',
            scope: 'openid profile phone offline_access',
            audience: getBrandEnv('auth0Audience'),
          },
        };

        /** @type {import('@auth0/auth0-spa-js').Auth0Client} */
        const auth0 = Vue.$auth0;

        auth0.getTokenSilently(differentAudienceOptions)
          .then((accessToken) => {
            http.defaults.headers.common.AUTHORIZATION =
            'Bearer ' + accessToken;
            commit('setUserAuthToken', {accessToken}); 
            resolve({accessToken});
          }).catch((err) => reject(err || 'Something went wrong') );
      })
    },
    /**
     * get person from auth0
     */
    getPersonFromAuth0() {
      return new Promise((resolve, reject) => {
        /** @type {import('@auth0/auth0-spa-js').Auth0Client} */
        const auth0 = Vue.$auth0;

        auth0.getUser().then(user => {
          resolve(user);
        }).catch(reject);
      })
    },
    /**
     * get person details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @returns {Promise<personDetails>}
     */
    getPerson({ commit }) {
      return new Promise((resolve, reject) => {
        window.solid.module('kyc').getIntialData()
          .then(({person}) => {
            commit('setPersonDetails', person);
            resolve(person);
          }).catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
        
      });
    },
    /**
     * update person details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.personId
     * @param {userState['personDetails']} param1.person
     * @returns {Promise<personDetails>}
     */
    updatePerson({ commit }, { personId, person }) {
      return new Promise((resolve, reject) => {
        http
          .patch('v1/person/' + personId, person)
          .then(({ data }) => {
            commit('setPersonDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * logout
     * @param {Object} param0
     * @param {userState} param0.state
     * @param {Function} param0.commit
     * @returns
     */
    logout() {
      return new Promise(() => {
        /** @type {import('@auth0/auth0-spa-js').Auth0Client} */
        const auth0 = Vue.$auth0;

        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      });
    },
    /**
     * get program config
     */
    getProgramConfig({ commit }) {
      return new Promise((resolve, reject) => {

        window.solid.module('kyc').getIntialData()
          .then(({programConfig}) => {
            commit('setProgramDetails', programConfig);
            resolve(programConfig);
          }).catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
        
      });
    }
  }
};

/**
 * @typedef {Object} kybState
 * @property {bussinessDetails} bussinessDetails
 * @property {string} bussinessDetailsFetchedStatus
 * @property {selectedBusinessType} selectedBusinessType
 */
export default {
  /**
   * @typedef {Object} bussinessDetail
   * @property {string} id
   * @property {string} legalName
   * @property {string} entityType
   * @property {string} dba
   * @property {string} email
   * @property {string} idNumber
   * @property {string} idType
   * @property {string} phone
   * @property {string} formationDate
   * @property {string} website
   * @property {string} about
   * @property {string} industry
   * @property {address} address
   * @property {kyb} kyb
   * @property {string} disclosureStatus
   */

  /**
   * @typedef {Array.<bussinessDetail>} bussinessDetails
   */
  bussinessDetails: [],
  bussinessDetailsFetchedStatus: 'notFetched',
  /** @typedef {bussinessDetail | null} selectedBusinessType */
  selectedBusinessType: null,
};

import Dashboard from './index.vue';
import { getBrandKey } from '@/utils/env';
const key = getBrandKey();

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      // {
      //   path: 'home',
      //   name: 'DashboardHome',
      //   component: () => import('./views/DashboardHome.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     preventBrowserBack: true
      //   }
      // },
      {
        path: 'home',
        name: 'DashboardHome',
        component: () => key === 'dolfin' || key === 'lcb' ? import('./views/DashboardHome.vue') : import('./views/DashboardDropin.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('./views/UserProfileDropin.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;

import { getBrandEnv } from '@/utils/env';
import { createAuth0Client } from '@auth0/auth0-spa-js';

export default {
  authClient: null,
  async setup() {
    /**
     * auth client
     * @see {@link https://auth0.com/docs/libraries/auth0js}
     * @see {@link https://auth0.com/docs/connections/passwordless/embedded-login/native}
     */
    this.authClient = await createAuth0Client({
      domain: getBrandEnv('auth0Domain'),
      clientId: getBrandEnv('auth0ClientId'),
      useRefreshTokens: true,
      cacheLocation: 'localstorage'
    });

  },
  install(Vue) {
    this.setup();
    Vue.getAuth0Instance = () => {
      return new Promise((resolve) => {
        const check = () => {
          if(this.authClient !== null) {
            Vue.prototype.$auth0 = this.authClient;
            Vue.$auth0 = this.authClient;
            resolve(this.authClient);
          } else {
            setTimeout(check, 1000);
          }
        };
        check();
      })
    }
  }
}
/**
 * @typedef {import('./state').dashbaordState} dashbaordState
 */
import { getSdkUIConfig, getBrandKey } from '@/utils/env';
import state from './state';

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get config data
     * @param {dashbaordState} state
     * @return {dashbaordState['config']}
     */
    getConfigData(state) {
      return state.config;
    },
  },
  mutations: {
    /**
     * update config data
     * @param {contactState} state
     * @param {contactState['createContactData']} payload
     */
    setConfigContact(state, payload) {
      state.config = payload;
    },
  },
  actions: {
    fetchConfigDataFromSdk({ commit }) {
      const key = getBrandKey();
      if(key === 'dolfin' || key === 'lcb') {
        const config =  {
          isTransactionsEnabled: true,
          isCardsEnabled: true,
          isTransferMoneyEnabled: true,
          isSendMoneyEnabled: true,
        }
        commit('setConfigContact', config);
      } else {
        Promise.all([
          getSdkUIConfig('showTransactions', false),
          getSdkUIConfig('showCards', false),
          getSdkUIConfig('showTransferMoney', false),
          getSdkUIConfig('showSendMoney', false),
        ]).then((d) => {
          const [showTransactions, showCards, showTransferMoney, showSendMoney] = d;
          const config =  {
            isTransactionsEnabled :showTransactions,
            isCardsEnabled: showCards,
            isTransferMoneyEnabled: showTransferMoney,
            isSendMoneyEnabled: showSendMoney,
          }
          commit('setConfigContact', config);
        })
      }
      
    }
  }
};

import store from '@/store';
import { Notification, Message } from 'element-ui';
import { getCountryDetailsFromPhoneNumber, getEnv } from './env';
import Vue from 'vue';
/**
 * api error handler
 * @param {*} message
 */
export const apiErrorMessage = (message) => {
  Notification.closeAll();
  var msg = getErrorMessage(message);

  Notification({
    type: 'error',
    message:
      typeof msg === 'string'
        ? msg
        : 'Something went wrong and we could not process your request.',
    title: 'Error'
  });
};
/**
 * get error message from response
 * @param {*} error
 */
const getErrorMessage = (error) => {
  var msg = error;
  if (typeof error === 'string') {
    msg = error;
  } else if (error.response && error.response.data) {
    return getErrorMessage(error.response.data);
  } else if (error.errorDesc) {
    msg = error.errorDesc;
  } else if (error.message) {
    msg = error.message;
  }
  return msg;
};
/**
 * check user is logged in or not
 */
export const isUserLoggedIn = () => {
  return store.getters['user/isUserLoggedIn'];
};

/**
 * Provides Contact name basis on available firstName and lastName
 */
export const contactName = (contactObj) => {
  let name = '';
  if (contactObj) {
    if (contactObj.firstName) {
      name = contactObj.firstName;
    }
    if (contactObj.lastName) {
      if (name.length > 0) {
        name += ' ';
      }
      name += contactObj.lastName;
    }
  }
  return name;
};

/** TODO remove after adding profile details in drop in */
export const formatPhoneNumber = (phoneNumber) => {

  const countryDetails = getCountryDetailsFromPhoneNumber(phoneNumber);
  const phoneNumberWithoutCode = (''+phoneNumber).replace(countryDetails.dial_code, '').replace(/ /g,'');
  console.log(phoneNumberWithoutCode)
  let mask = countryDetails.mask;

  let i = 0;
  const numbers = mask.split('').map((c) => {
    if(c === '#') {
      const returnValue = phoneNumberWithoutCode[i] || '';
      i++;
      return returnValue;
    }
    return c;
  }).join('');
  return countryDetails.dial_code + ' ' + numbers;
};


export const updateBrand = () => {
  const brandColor = store.getters['user/getBrandColor'];
  const programDetails = store.getters['user/getProgramDetails'];
  const root = document.documentElement;
  root.style.setProperty('--branding-primary', brandColor);
  if(programDetails) {
    root.style.setProperty('--dark-branding-primary', programDetails.brand.darkPrimaryTextColor);
  }
};

export const textCopy = (textToBeCopied, callback) => {
  navigator.clipboard.writeText(textToBeCopied).then(
    function () {
      if (callback) {
        callback(null);
      }
    },
    function (e) {
      if (callback) {
        callback(e);
      }
    }
  );
};


export const networkOnline = (customClass = '') => {
  Message.closeAll();
  Message({
    message: 'Connection restored',
    type: 'success',
    customClass : typeof customClass === 'string' ? customClass : ''
  });
}

export const networkOffline = (customClass = '') => {
  Message({
    message: 'Please make sure you are connected to the internet',
    type: 'error',
    duration: 0,
    customClass : typeof customClass === 'string' ? customClass : ''
  });
}

export const currencyFormatter = (value, currency = 'USD') => {
  
  let number = value;

  if(typeof number === 'string') {
    number = number.replace('$', '');
    number = parseFloat(number);
  }

  if(isNaN(number)) {
    return value;
  }

  if(currency) {

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
  
    return formatter.format(number);
  } else {
    return number === 0 ? '0.00' : new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 20
    }).format(number)
  }

};

export const initSolid = (accessToken) => {
  return new Promise((resolve, reject) => {
    const activeEnv = store.getters['getActiveEnv'];

    const startSdk = () => {
      // init solid sdk
      window.solid = new window.Solid({
        className: 'solid-dropin',
        authToken: accessToken, 
        deviceId: Vue.$http.defaults.headers.common['SD-Device-ID'],
        mode: activeEnv === 'prod' ? 'live' : 'sandbox',
        onReady: () => {
          resolve();
        },
        onError: (e) => {
          if(e === 'INVALID_AUTH_TOKEN') {
            redirectToLogin();
          }
          console.log('error', e)
        },
        onMessage: (m) => {
          console.log('message', m)
        },
        onTokenExpired: () => {
          return new Promise((resolve, reject) => {
            store.dispatch('user/checkSession')
              .then((data) => {
                resolve(data.accessToken)
              }).catch(reject)
            
          })
        }
      });
    }

    // solid sdk js
    if(!window.solid) {
      const sdkScript = document.createElement('script');
      sdkScript.id = 'solid-sdk-script';
      sdkScript.crossorigin='anonymous'
      const url = getEnv('VUE_APP_SDK_URL');
      sdkScript.src = url;
      sdkScript.type = 'text/javascript';

      /**
     * onload callback
     */
      sdkScript.onload = () => {
        startSdk();
      };
      sdkScript.onerror = () => {
        reject();
      }
      document.head.append(sdkScript);
    } else {
      startSdk();
    }

   
  })
}

function redirectToLogin() {
  window.location.reload();
}
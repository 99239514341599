<template>
  <div id="loader" class="init-loader">
    <BrandImg
      class="init-loader__logo"
      src="logos/loading-icon.svg"
      alt="Logo" />
    <p class="init-loader__text">
      {{ statusText }}
    </p>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { setEnv } from '@/utils/env';
import BrandImg from '@/components/BrandImg.vue';
import loginMixins from '../mixins/login';
import Vue from 'vue';
import auth0 from '@/plugins/auth0';
export default {
  mixins: [loginMixins],
  data() {
    return {
      statusText: 'Verifing details...'
    }
  },
  created() {
    const env =  window.sessionStorage.getItem('selectedEnv')
    setEnv(env);
    setTimeout(() => {
      (async () => {
        try {

          Vue.use(auth0);
          await Vue.getAuth0Instance()
      
          const query = this.$route.query
          if(!query || !query.haveUserData) {
            await this.handleRedirectCallback();
          }
          const {accessToken} = await this.checkSession();
          this.verify(accessToken, undefined, (statusText) => {
            this.statusText = statusText;
          }).catch((e) => {
            console.log('e',e)
            const message = e.errorDescription || e.description || e.error || e.message || 'Something went wrong!!'
            // this.apiErrorMessage(message);
            this.$router.push('/user/loginError?error='+message);
          });
        } catch(e) {
          console.log(e)
        // this.$router.push('/welcome');
        }
      })()
    }, 1000)
  },
  methods: {
    ...mapActions('user', ['checkSession', 'handleRedirectCallback']),
  },
  components: { BrandImg }
}
</script>
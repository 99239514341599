/**
 * @typedef {Object} userState
 * @property {userDetails} userDetails
 * @property {authTokenDetails} authTokenDetails
 * @property {personDetails} personDetails
 * @property {Array.<account>} accounts
 * @property {account | null} selectedAccount
 * @property {programDetails} programDetails
 */
export default {
  /**
   * @typedef {Object} userDetails user details object
   * @property {string | null} phone phone number
   * @property {boolean | null} idNumberLast4Required ssn required
   * @property {string | null} otp otp number
   * @property {string | null} externalId external Id
   */
  userDetails: {
    phone: null,
    idNumberLast4Required: null,
    otp: null,
    externalId: null
  },
  /**
   * @typedef {Object} authTokenDetails user auth token details object
   * @property {string | null} accessToken
   * @property {string | null} idToken
   * @property {string | null} scope
   * @property {number | null} expiresIn
   * @property {string | null} bearer
   */
  authTokenDetails: {
    accessToken: null,
    idToken: null,
    scope: null,
    expiresIn: null,
    bearer: null
  },
  /**
   * @typedef {Object} personDetails person details object
   * @property {string} id
   * @property {string} firstName
   * @property {string} middleName
   * @property {string} lastName
   * @property {number} phone
   * @property {boolean} phoneVerified
   * @property {string} email
   * @property {boolean} emailVerified
   * @property {string} dateOfBirth
   * @property {string} idNumber
   * @property {string} idNumber
   * @property {string} idType
   * @property {personAddress} address
   * @property {personKyc} kyc
   * @property {personIdv} idv
   * @property {personSource} source
   *
   * @typedef {Object} personAddress
   * @property {string} addressType
   * @property {string} line1
   * @property {string} line2
   * @property {string} city
   * @property {string} state
   * @property {string} country
   * @property {string} postalCode
   *
   * @typedef {Object} personKyc
   * @property {string} id
   * @property {string} personId
   * @property {string} status
   * @property {string} reviewMessage
   * @property {string} createdAt
   * @property {string} modifiedAt
   *
   * @typedef {Object} personIdv
   * @property {string} status
   *
   * @typedef {Object} personSource
   * @property {string} partnerId
   * @property {string} partnerName
   */
  personDetails: {},
  /**
   * @typedef {Object} account
   * @property {string} id
   * @property {string} businessId
   * @property {string} accountNumber
   * @property {string} routingNumber
   * @property {string} label
   * @property {string} availableBalance
   * @property {string} status
   * @property {string} createdAt
   * @property {string} modifiedAt
   */
  accounts: [],
  selectedAccount: null,
  /**
   * @typedef {Object} programDetails
   * @property {String} accountNumberPrefix
   * @property {Object} bank
   * @property {Object} billing
   * @property {Object} brand
   * @property {Object} card
   * @property {Object} company
   * @property {String} createdAt
   * @property {String} description
   * @property {String} id
   * @property {Boolean} idv
   * @property {Object} legal
   * @property {Object} limits
   * @property {String} modifiedAt
   * @property {String} productName
   * @property {String} programType
   * @property {Object} receive
   * @property {Object} send
   * @property {String} status
   */
  programDetails: null
};

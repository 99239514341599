import store from '@/store';
import userStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('user', userStore);
  } else {
    if (!userStore.registered) {
      store.registerModule('user', userStore, {
        preserveState: !!store.state['user']
      });
      userStore.registered = true;
    }
  }
}

export default function init() {
  registerStore();
}

<template>
  <router-view />
</template>
<script>
import { registerStore } from './setup';
export default {
  name: 'Kyc',
  beforeCreate() {
    registerStore();
  },
  created() {}
};
</script>

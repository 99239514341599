import Contact from './index.vue';

const routes = [
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    children: [
      {
        path: 'list',
        name: 'ContactList',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactsDropin.vue')
      },
      {
        path: 'details',
        name: 'ContactDetails',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactDetailsDropin.vue')
      },
    ]
  }
];

export default routes;

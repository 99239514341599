import Fund from './index.vue';
import { getBrandKey } from '@/utils/env';
const key = getBrandKey();

const routes = [
  {
    path: '/fund',
    component: Fund,
    children: [
      {
        path: '',
        name: 'FundDetails',
        component: () => key === 'dolfin' || key === 'lcb' ? import('./views/Fund.vue') : import('./views/FundDropin.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
    ]
  }
];

export default routes;

import Vue from 'vue';
/**
 * @typedef {import('./state').kybState} kybState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get business list from store
     * @param {kybState} state
     * @returns {kybState['bussinessDetails']}
     */
    getBusinessList(state) {
      return state.bussinessDetails;
    },
    /**
     * get business details fetched status
     * @param {kybState} state
     * @returns {kybState['bussinessDetailsFetchedStatus']}
     */
    getBussinessDetailsFetchedStatus(state) {
      return state.bussinessDetailsFetchedStatus;
    },
    /**
     * get business details fetched status
     * @param {kybState} state
     * @returns {kybState['selectedBusinessType']}
     */
    getSelectedBusiness(state) {
      return state.selectedBusinessType;
    },
  },
  mutations: {
    /**
     * set business list
     * @param {kybState} state
     * @param {kybState['bussinessDetails']} payload
     */
    setBusinessList(state, payload) {
      state.bussinessDetails = payload;
      state.bussinessDetailsFetchedStatus = 'fetched';
    },
    /**
     * set selected business
     * @param {kybState} state
     * @param {kybState['selectedBusinessType']} payload
     */
    setSelectedBusiness(state, payload) {
      state.selectedBusinessType = payload;
    },
  },
  actions: {
    /**
     * fetch all business list
     * @param {Object} param0
     * @param {Function} param0.commit
     */
    listAllBusiness({ commit }) {
      return new Promise((resolve, reject) => {
        window.solid.module('kyb')
          .getBusinessList()
          .then((data) => {
            commit('setBusinessList', data.data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     * @param {businessId} payload.businessId
     * @param {*} payload.business
     */
    updateBusiness({ commit }, { businessId, business }) {
      return new Promise((resolve, reject) => {
        http
          .patch('v1/business/' + businessId, business)
          .then(({ data }) => {
            commit('setSelectedBusiness', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchBusiness(_, businessId) {
      return new Promise((resolve, reject) => {
        window.solid.module('kyb')
          .getBusinessDetails(businessId)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * list all owners
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchAllOwners(_, businessId) {
      return new Promise((resolve, reject) => {
        console.log('fetchAllOwners')
        window.solid.module('kyb')
          .getAllOwners(businessId)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
  }
};

/**
 * @typedef {import('./state').cardState}
 */
import state from './state';
export default {
  namespaced: true,
  state,
  mutations: {
    /**
     * update selected card
     * @param {state} state
     * @param {state['createCardData']} payload
     */
    updateSelectedCard(state, payload) {
      state.selectedCard = payload;
    },
  },
  getters: {
    /**
     * get selected card data
     * @param {state} state
     * @returns {state['selectedCard']}
     */
    getSelectedCard(state) {
      return state.selectedCard;
    },
  },
  actions: {

  }
};

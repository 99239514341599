/**
 * @typedef {import('./state').transactionState} transactionState
 */
import state from './state';

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get selected transaction
     * @param {transactionState} state
     */
    getSelectedTransaction(state) {
      return state.selectedTransaction;
    },
    /**
     * get filters
     * @param {transactionState} state
     */
    getFilters(state) {
      return state.filters;
    },
    /**
     * get dropin filters
     * @param {transactionState} state
     */
    getDropinFilters(state) {
      return state.dropinFilters;
    },
    
  },
  mutations: {
    /**
     * set selected transactions
     * @param {transactionState} state
     * @param {transactionState['transactions']} payload
     */
    setSelectedTransaction(state, payload) {
      state.selectedTransaction = payload;
    },
    /**
     * update transaction filters
     * @param {transactionState} state
     * @param {transactionState['filters']} payload
     */
    updateFilters(state, payload) {
      state.filters = payload;
    },
    /**
     * update transaction filters
     * @param {transactionState} state
     * @param {transactionState['dropinFilters']} payload
     */
    updateDropinFilters(state, payload) {
      state.dropinFilters = payload;
    }
  },
  actions: {
  }
};

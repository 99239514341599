import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getBrandKey, getEnv } from '@/utils/env';
import axios from 'axios';

Vue.use(VueI18n);
/**
 * load messages from locale files
 * @returns {Object} messages
 */
function loadLocaleMessages() {

  const localesNew = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};

  localesNew.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = localesNew(key);
    }
  });

  return messages;
}

const i18n = new VueI18n({
  locale:
    navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});

/**
 * load brand locales
 */
export const loadLanguageAsync = () => {
  const key = getBrandKey();

  const assetsUrl = getEnv('VUE_APP_ASSETS');

  /** @type {import('axios').AxiosInstance} */
  axios.get(`${assetsUrl}/${key}/locales/en.json`)
    .then((res) => {
      if(res.data) {
        const en = i18n.getLocaleMessage('en');
        i18n.setLocaleMessage('en', {
          ...en,
          ...res.data
        })
      }
    })
    .catch((e) => {
      console.log('Error:',e);
    })

}


export default i18n;

/**
 * @typedef {import('./state').CheckDepositState} CheckDepositState
 */
import state from './state';

export default {
  namespaced: true,
  state,
  getters: {
  },
  mutations: {
    
  },
  actions: {
  }
};
